import React, { useEffect, useState } from "react";

const Posts = ({
  mainPageArticles,
  sectionHeadline,
  startNumber,
  articlesToShow,
}) => {
  const [browserWidth, setBrowserWidth] = useState();
  const [headlineLength, setHeadlineLength] = useState();
  const [paragraphLength, setParagraphLength] = useState();

  let newHeadlineLength;
  let newParagraphLength;

  const handleResize = () => {
    setBrowserWidth(window.innerWidth);
  };

  const determineCharacterLength = () => {
    if (browserWidth <= 480) {
      newHeadlineLength = 60;
    } else if (browserWidth <= 530) {
      newHeadlineLength = 90;
    } else if (browserWidth <= 600) {
      newHeadlineLength = 45;
      newParagraphLength = 55;
    } else if (browserWidth <= 660) {
      newHeadlineLength = 45;
      newParagraphLength = 75;
    } else if (browserWidth <= 780) {
      newHeadlineLength = 60;
      newParagraphLength = 90;
    } else if (browserWidth <= 840) {
      newHeadlineLength = 100;
      newParagraphLength = 120;
    } else if (browserWidth <= 1150) {
      newHeadlineLength = 115;
      newParagraphLength = 135;
    } else if (browserWidth < 1170) {
      newHeadlineLength = 45;
      newParagraphLength = 65;
    } else if (browserWidth < 1400) {
      newHeadlineLength = 45;
      newParagraphLength = 75;
    } else if (browserWidth < 1650) {
      newHeadlineLength = 60;
      newParagraphLength = 90;
    } else if (browserWidth < 1800) {
      newHeadlineLength = 100;
      newParagraphLength = 120;
    } else if (browserWidth > 1800) {
      newHeadlineLength = 115;
      newParagraphLength = 135;
    }
    return { newHeadlineLength, newParagraphLength };
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const { newHeadlineLength, newParagraphLength } =
      determineCharacterLength();
    setHeadlineLength(newHeadlineLength);
    setParagraphLength(newParagraphLength);
  }, [browserWidth]);

  const adjustHeaderParagraphSize = (
    headline,
    headlineLength,
    paragraph,
    paragraphLength
  ) => {
    //? regex to find whitespaces
    const regex = /^\s+|\s+$/g;
    if (headline.length > headlineLength) {
      //? if headline has whitespaces at beginning or end, remove them
      if (regex.test(headline)) {
        headline = headline.replace(/\s+/g, " ").trim();
      }
      headline = headline.slice(0, headlineLength - 3) + "...";
    }
    if (paragraph.length > paragraphLength) {
      //? if paragraph has whitespaces at beginning or end, remove them
      if (regex.test(paragraph)) {
        paragraph = paragraph.replace(/\s+/g, " ").trim();
      }

      paragraph = paragraph.slice(0, paragraphLength - 3) + "...";
    }
    return { headline, paragraph };
  };

  if (mainPageArticles) {
    return (
      <article className="information">
        <h2 className="information__header">{sectionHeadline}</h2>
        {mainPageArticles.map((article, index) => {
          if (index >= startNumber && index < articlesToShow + startNumber) {
            const slug = article.slug;
            const uniqueID = article.unique_id;
            const articleURL = uniqueID + "/" + slug;
            const imgItem = article.data.find((item) => item.tag === "img");
            let thumbnail;

            if (imgItem && imgItem.text) {
              thumbnail = imgItem.text;
            } else {
              thumbnail = "https://picsum.photos/1900/800";
            }
            let headline = article.data.find((item) => item.tag === "h1").text;
            let paragraph = article.data.find((item) => item.tag === "p").text;

            ({ headline, paragraph } = adjustHeaderParagraphSize(
              headline,
              headlineLength,
              paragraph,
              paragraphLength
            ));

            return (
              <React.Fragment key={index}>
                <article key={index} className="information__post">
                  <a className="information__post__link" href={articleURL}>
                    <div
                      className="information__thumbnailHolder"
                      style={{
                        backgroundImage: `url(${thumbnail})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </a>
                  <section className="postInfo">
                    <a href={articleURL}>
                      <h3 className="postInfo__header">{headline}</h3>
                    </a>
                    <p className="postInfo__paragraph">{paragraph}</p>
                  </section>
                </article>
              </React.Fragment>
            );
          }
        })}
      </article>
    );
  }
};

export default Posts;
